var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { FormControl, TextField, } from "@mui/material";
function CustomInput(_a) {
    var label = _a.label, error = _a.error, rest = __rest(_a, ["label", "error"]);
    return (_jsx(FormControl, __assign({ sx: { width: "100%" } }, { children: _jsx(TextField, __assign({ variant: "outlined", fullWidth: true, label: label, margin: "dense", FormHelperTextProps: { sx: { fontWeight: "normal", fontSize: 12 } } }, rest, { error: !!error, helperText: !!error ? error.message : rest.helperText })) })));
}
export default CustomInput;
