var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Box } from "@mui/material";
export default function DiagonalSeparator() {
    return (_jsx(Box, __assign({ mt: 5, sx: { position: "relative", paddingTop: "40px", marginTop: "0", minHeight: "20px" } }, { children: _jsx("div", __assign({ className: "custom-shape-divider-top-1649097473" }, { children: _jsx("svg", __assign({ "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 1200 120", preserveAspectRatio: "none" }, { children: _jsx("path", { d: "M649.97 0L550.03 0 599.91 54.12 649.97 0z", className: "shape-fill" }) })) })) })));
}
