var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Grid, Typography } from "@mui/material";
import CustomCard from "../../components/CustomCard";
import { AssignmentInd, MenuBook, Scanner, Accessible } from "@mui/icons-material";
import CopyService from "../../assets/img/copy.jpg";
import BindingService from "../../assets/img/notebook.jpg";
import LabeledService from "../../assets/img/rotulado.jpeg";
import PlasticizedService from "../../assets/img/plastificado.jpeg";
export default function Services() {
    return (_jsxs(Box, __assign({ mt: 5, className: "section" }, { children: [_jsx(Typography, __assign({ variant: "h4", color: "#cd26af" }, { children: " Nuestros servicios " })), _jsxs(Grid, __assign({ container: true }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 4, lg: 3 }, { children: _jsx(CustomCard, { icon: _jsx(MenuBook, {}), bgColorIcon: "#6e2ed0", title: "Encuadernado", description: "Variedad de colores en tapas y tamaños de anillado.", urlImgService: BindingService }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 4, lg: 3 }, { children: _jsx(CustomCard, { icon: _jsx(Scanner, {}), bgColorIcon: "#f100c7", title: "Impresión | Copias | Escaneo", description: "Ofrecemos la mejor calidad de impresión envariedad de hojas y tamaños.", urlImgService: CopyService }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 4, lg: 3 }, { children: _jsx(CustomCard, { icon: _jsx(Accessible, {}), bgColorIcon: "#3dbbb8", title: "Rotulado | Stickers | Ploteo", description: "Diseños personalizados, elige el color, el diseño y nosotros hacemos el resto.", urlImgService: LabeledService }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 6, md: 4, lg: 3 }, { children: _jsx(CustomCard, { icon: _jsx(AssignmentInd, {}), bgColorIcon: "#70ce84", title: "Plastificado", description: "Protege tus documentos de la mejor manera.", urlImgService: PlasticizedService }) }))] }))] })));
}
