var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, forwardRef } from "react";
import { Paper, Typography, Grid, Button, Box, Stack, Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomInputForm from "../../components/Form/CustomInputForm";
import DiagonalSeparator from "../../components/DiagonalSeparator";
import BackdropLoader from "../../components/BackdropLoader";
var Alert = forwardRef(function Alert(props, ref) {
    return _jsx(MuiAlert, __assign({ elevation: 6, ref: ref, variant: "filled" }, props));
});
export default function Suggestion() {
    var _a = useState(false), openBackdrop = _a[0], setOpenBackdrop = _a[1];
    var _b = useState({ open: false, msg: "", state: "" }), openSnackBar = _b[0], setOpenSnackBar = _b[1];
    var validationSchema = Yup.object().shape({
        msg: Yup.string().required("¿Cuál sería su sugerencia?"),
        email: Yup.string()
            .required('Por favor introducir su correo')
            .email('Por favor introducir un correo válido'),
    });
    var handleCloseBackdrop = function () {
        setOpenBackdrop(false);
    };
    var handleToggleBackdrop = function () {
        setOpenBackdrop(!openBackdrop);
    };
    var _c = useForm({
        resolver: yupResolver(validationSchema)
    }), register = _c.register, handleSubmit = _c.handleSubmit, reset = _c.reset, control = _c.control, errors = _c.formState.errors;
    var methods = useForm({
        resolver: yupResolver(validationSchema),
    });
    function onSendSubmit(data) {
        return __awaiter(this, void 0, void 0, function () {
            var myHeaders, raw, requestOptions;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        handleToggleBackdrop();
                        myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/json");
                        raw = JSON.stringify({
                            "email": data.email,
                            "text": data.msg
                        });
                        requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: raw,
                            redirect: 'follow'
                        };
                        return [4 /*yield*/, fetch("https://mympoint-api.herokuapp.com/api/mail", requestOptions)
                                .then(function (response) { return response.text(); })
                                .then(function (result) { setOpenSnackBar({ open: true, state: "success", msg: "El correo fue enviado satisfactoriamente" }); reset({ email: "", msg: "" }); })
                                .catch(function (error) { setOpenSnackBar({ open: true, state: "error", msg: "No fue posible enviar el correo, por favor intentar nuevamente" }); })];
                    case 1:
                        _a.sent();
                        handleCloseBackdrop();
                        return [2 /*return*/];
                }
            });
        });
    }
    var handleClose = function (event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackBar({ open: false, msg: "", state: "" });
    };
    return (_jsxs(Box, __assign({ className: "section" }, { children: [openBackdrop && _jsx(BackdropLoader, {}), _jsx(DiagonalSeparator, {}), _jsx(Typography, __assign({ variant: "h4", color: "#cd26af" }, { children: "Buz\u00F3n de sugerencias" })), _jsx(Stack, __assign({ spacing: 2, sx: { width: '100%' } }, { children: _jsx(Snackbar, __assign({ open: openSnackBar.open, autoHideDuration: 4000, onClose: handleClose, anchorOrigin: { horizontal: "center", vertical: "top" } }, { children: _jsx(Alert, __assign({ onClose: handleClose, severity: openSnackBar.state === "error" ? "error" : "success", sx: { width: '100%' } }, { children: openSnackBar.msg })) })) })), _jsx(Paper, __assign({ elevation: 10, component: "form", role: "form", onSubmit: handleSubmit(onSendSubmit), className: "form-container" }, { children: _jsxs(Grid, __assign({ container: true }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, p: 2 }, { children: _jsx(CustomInputForm, { name: "email", control: control, error: errors.email, label: "Correo electr\u00F3nico" }) })), _jsx(Grid, __assign({ item: true, xs: 12, p: 2 }, { children: _jsx(CustomInputForm, { name: "msg", control: control, error: errors.msg, label: "Sugerencia", multiline: true, rows: 3 }) })), _jsx(Grid, __assign({ item: true, xs: 12, p: 2, display: "flex", justifyContent: "center" }, { children: _jsx(Button, __assign({ type: "submit", variant: "outlined", color: "secondary" }, { children: "Enviar" })) }))] })) }))] })));
}
