var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Box, Grid, Typography } from "@mui/material";
export default function index() {
    var currentTime = new Date();
    var year = currentTime.getFullYear();
    return (_jsxs(_Fragment, { children: [_jsx(Box, __assign({ sx: { minHeight: "150px", backgroundColor: "#CD26B2", position: "relative" }, className: "section" }, { children: _jsx("div", __assign({ className: "custom-shape-divider-top-1649105813" }, { children: _jsx("svg", __assign({ "data-name": "Layer 1", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 1200 120", preserveAspectRatio: "none" }, { children: _jsx("path", { d: "M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z", className: "shape-fill" }) })) })) })), _jsx(Box, __assign({ position: "relative", marginTop: "-80px", marginLeft: "20px" }, { children: _jsxs(Grid, __assign({ container: true, className: "footer" }, { children: [_jsx(Grid, __assign({ item: true, md: 6, sx: { display: "flex", alignItems: "center" } }, { children: _jsxs(Typography, __assign({ variant: 'body2', sx: { marginRight: "5px", color: "white" } }, { children: ["\u00A9 ", year, " - ", _jsx("a", __assign({ className: "hyperlink", href: "https://www.mympoint.com" }, { children: "M&M Point" })), " "] })) })), _jsxs(Grid, __assign({ item: true, md: 6, sx: { display: "flex", flexFlow: "column", alignItems: "self-end", paddingRight: "20px", color: "white" }, className: "slogan-footer" }, { children: [_jsx(Typography, __assign({ variant: "h4", fontSize: 20, p: 0 }, { children: "M&M Point" })), _jsx(Typography, __assign({ variant: "h4", fontSize: 20, p: 0 }, { children: "\u00A1M\u00E1s que una librer\u00EDa, un espacio para vos!" }))] }))] })) }))] }));
}
