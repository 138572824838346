var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Typography } from "@mui/material";
import ReactCompareImage from "react-compare-image";
import BackdropLoader from "../../components/BackdropLoader";
import Galeria from "../../assets/img/galeria.jpg";
import Madero from "../../assets/img/madero.jpg";
import Logomym from "../../assets/logo/logo.png";
export default function Header() {
    return (_jsxs(Box, __assign({ className: "slider-container" }, { children: [_jsx(ReactCompareImage, { leftImage: Galeria, rightImage: Madero, sliderPositionPercentage: 0.95, hover: true, skeleton: _jsx(BackdropLoader, {}) }), _jsx("div", { className: "shadow1" }), _jsx("div", { className: "shadow2" }), _jsx("div", { className: "shadow3" }), _jsx("div", { className: "shadow4" }), _jsx(Box, __assign({ sx: {
                    color: "white",
                    zIndex: "11",
                    top: "45%",
                    width: "100%",
                    position: "absolute",
                    textAlign: "center"
                }, className: "title-header" }, { children: _jsx(Typography, __assign({ variant: "h4", fontSize: 25, p: 0 }, { children: "\u00A1M\u00E1s que una librer\u00EDa, un espacio para vos!" })) })), _jsx(Box, __assign({ className: "logo-developed", textAlign: "center" }, { children: _jsx("img", { src: Logomym, width: "150px", alt: "Logo M&M Point" }) }))] })));
}
