var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper, Card, CardHeader, CardMedia, CardContent, CardActions, Avatar, Typography } from "@mui/material";
import { FacebookShareButton, FacebookIcon, WhatsappShareButton, WhatsappIcon } from "react-share";
export default function RecipeReviewCard(_a) {
    var icon = _a.icon, title = _a.title, subheader = _a.subheader, bgColorIcon = _a.bgColorIcon, description = _a.description, urlImgService = _a.urlImgService;
    var bgColor = bgColorIcon ? bgColorIcon : "#b216c6";
    return (_jsx(Paper, __assign({ elevation: 8, sx: { margin: "20px" } }, { children: _jsxs(Card, { children: [_jsx(CardHeader, { avatar: _jsx(Avatar, __assign({ sx: { bgcolor: bgColor }, "aria-label": "recipe" }, { children: icon })), title: title, subheader: subheader }), _jsx(CardMedia, { component: "img", height: "250", image: urlImgService, alt: "Libreria" }), _jsx(CardContent, { children: _jsx(Typography, __assign({ variant: "subtitle1", color: "text.secondary" }, { children: description })) }), _jsx(Typography, __assign({ variant: "body2", ml: 1, fontSize: 11, fontWeight: "500" }, { children: "Compartir por:" })), _jsxs(CardActions, __assign({ disableSpacing: true }, { children: [_jsx(FacebookShareButton, __assign({ url: "https://www.mympoint.com", windowWidth: 100, quote: "M&M Point tiene para ti articulos escolares y de oficina, juguetes, purpurinas, mandalas y servicios de impresión, copiado, escaneo, rotulado y creación de stickers, globos personalizados y mucho más...", hashtag: "#mympoint" }, { children: _jsx(FacebookIcon, { size: 30, round: true }) })), _jsx(WhatsappShareButton, __assign({ url: "https://www.mympoint.com", windowWidth: 100, title: "M&M Point tiene para ti articulos escolares y de oficina, juguetes, purpurinas, mandalas y servicios de impresión, copiado, escaneo, rotulado y creación de stickers, globos personalizados y mucho más..." }, { children: _jsx(WhatsappIcon, { size: 30, round: true }) }))] }))] }) })));
}
