var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Container, Box, SpeedDial, SpeedDialAction } from "@mui/material";
import Header from "./Sections/Header";
import Services from "./Sections/Services";
import BindingService from "./Sections/BindingService";
import Footer from "./Sections/Footer";
import { WhatsApp, } from '@mui/icons-material';
import "./assets/scss/style.scss";
import Suggestion from './Sections/Suggestion';
var actions = [
    { icon: _jsx(WhatsApp, {}), name: 'Paseo Galería', href: "https://wa.me/595994343311?text=Hola%20quisiera%20información%20sobre%20" },
    { icon: _jsx(WhatsApp, {}), name: 'Plaza Madero', href: "https://wa.me/595994133311?text=Hola%20quisiera%20información%20sobre%20" },
];
function App() {
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var handleOpen = function () { return setOpen(true); };
    var handleClose = function () { return setOpen(false); };
    return (_jsxs(Container, __assign({ maxWidth: false, sx: { paddingLeft: 0, margin: 0 }, className: "main-container" }, { children: [_jsxs(Box, { children: [_jsx(Header, {}), _jsx(Services, {}), _jsx(BindingService, {}), _jsx(Suggestion, {}), _jsx(Footer, {})] }), _jsx(Box, __assign({ sx: { transform: 'translateZ(0px)', flexGrow: 1, position: 'fixed', bottom: "20px", right: "40px", zIndex: "11" }, className: "whatsapp-contact" }, { children: _jsx(SpeedDial, __assign({ ariaLabel: "Contacto por Whatsapp", icon: _jsx(WhatsApp, {}), onClose: handleClose, onOpen: handleOpen, open: open, FabProps: {
                        sx: {
                            bgcolor: 'success.main',
                            '&:hover': {
                                bgcolor: 'success.main',
                            }
                        }
                    } }, { children: actions.map(function (action) { return (_jsx(SpeedDialAction, { icon: action.icon, tooltipTitle: action.name, tooltipOpen: true, onClick: handleClose, FabProps: {
                            href: "".concat(action.href),
                            formTarget: "_blank",
                            sx: {
                                color: "white",
                                bgcolor: 'success.main',
                                '&:hover': {
                                    bgcolor: "".concat(action.name === "Paseo Galería" ? "#1771fb" : "#fb6917"),
                                }
                            }
                        } }, action.name)); }) })) }))] })));
}
export default App;
