var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Backdrop, Typography } from '@mui/material';
// import CircularProgress from '@mui/material/CircularProgress';
import preloader from "../../assets/img/preloader.gif";
export default function SimpleBackdrop() {
    return (_jsx("div", { children: _jsxs(Backdrop, __assign({ sx: { display: "flex", flexFlow: "column", backgroundColor: "rgb(255 255 255 / 83%)", color: '#fff', zIndex: function (theme) { return theme.zIndex.drawer + 1; } }, open: true }, { children: [_jsx(Typography, __assign({ variant: "h4", color: "#cd26af" }, { children: "Cargando" })), _jsx("img", { src: preloader, alt: "loading" })] })) }));
}
