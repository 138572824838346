var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Box, Typography, Divider } from "@mui/material";
import { StarBorderPurple500 } from "@mui/icons-material";
import Banner from "../../assets/img/BindingService.png";
import SeparatorColor from "../../components/SeparatorColor";
import Localiation from "../Localization";
export default function Services() {
    return (_jsxs(Box, { children: [_jsx(SeparatorColor, {}), _jsxs("div", __assign({ className: "banner-container section" }, { children: [_jsx(Typography, __assign({ variant: "h4", color: "white" }, { children: "Dise\u00F1os exclusivos" })), _jsx(Typography, __assign({ variant: "h6", color: "white", ml: 6 }, { children: "en Tarjetas de Presentaci\u00F3n, Flyers, Banners, Vinilos y mucho m\u00E1s" })), _jsx(Box, __assign({ display: "flex", justifyContent: "center" }, { children: _jsx("img", { src: Banner, alt: "Servicios de ploteo en Paraguay" }) })), _jsx(Localiation, {})] })), _jsx(Divider, __assign({ sx: { backgroundColor: "#781084", paddingBottom: "10px" } }, { children: _jsx(StarBorderPurple500, {}) }))] }));
}
